import * as firebase from 'firebase';

firebase.initializeApp({
  apiKey: "AIzaSyAWn2iWAlF8kL4mbq_dNDKTMS_WhHvP2Aw",
  authDomain: "numeric-marker-265703.firebaseapp.com",
  databaseURL: "https://numeric-marker-265703.firebaseio.com",
  projectId: "numeric-marker-265703",
  storageBucket: "numeric-marker-265703.appspot.com",
  messagingSenderId: "789271608821",
  appId: "1:789271608821:web:30d097b7250c97a034f4bc",
  measurementId: "G-BE2W1CDDYN"
});
export const authRef = firebase.auth();
