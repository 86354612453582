import React from "react";
import '../style/modal.css';

export default function TermsAndConditionModal() {
  const [showModal, setShowModal] = React.useState(false);
  return (
    <>
       <p
        className="text-white py-3"
        // type="button"
        style={{ transition: "all .15s ease" }}
        onClick={() => setShowModal(true)}
      >
        Terms and Conditions
      </p>
      {showModal ? (
        <>
          <div
            className="modal h-2/3 overflow-y-auto justify-center items-center overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none"
            onClick={() => setShowModal(false)}
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="text-black flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                  <h3 className="text-3xl text-black font-semibold">
                    Terms and Conditions
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="text-black bg-transparent opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto text-left">

                  <p className="my-4 text-gray-600 text-lg leading-relaxed justify-start content-start">
                    By downloading or using the app, these terms will automatically apply to you – you should make sure therefore that you read them carefully before using the app. You’re not allowed to copy, or modify the app, any part of the app, or our trademarks in any way. You’re not allowed to attempt to extract the source code of the app, and you also shouldn’t try to translate the app into other languages, or make derivative versions. The app itself, and all the trade marks, copyright, database rights and other intellectual property rights related to it, still belong to Aworan.
                    <br></br><br></br>

                    Aworan is committed to ensuring that the app is as useful and efficient as possible. For that reason, we reserve the right to make changes to the app or to charge for its services, at any time and for any reason. We will never charge you for the app or its services without making it very clear to you exactly what you’re paying for. 
                    If you delete Content, Dribbble will use reasonable efforts to remove it from the Service, but you acknowledge that caching or references to the Content may not be made immediately unavailable.
                    <br></br>
                    <br></br>

                    The [Aworan] app stores and processes some personal data that you have provided to us, in order to provide our Service. It’s your responsibility to keep your phone and access to the app secure. We therefore recommend that you do not jailbreak or root your phone, which is the process of removing software restrictions and limitations imposed by the official operating system of your device. It could make your phone vulnerable to malware/viruses/malicious programs, compromise your phone’s security features and it could mean that the [Aworan] app won’t work properly or at all.
                    <br></br>
                    <br></br>

                    The app does use third party services that declare their own Terms and Conditions.
                    <br></br>
                    <br></br>

                    Link to Terms and Conditions of third party service providers used by the app
                  </p>
                  {/*  */}

                  <p className="my-4 text-black text-xl leading-relaxed font-semibold">
                    Rules of Use
                  </p>
                  <p className="text-black text-lg leading-relaxed font-semibold">
                  The Short
                  </p>
                          <p className="my-2 text-gray-600 text-lg leading-relaxed justify-start content-start">
                  We are trying to build a healthy environment for artists to collaborate with each other. Help us foster this community. Post only your own photos, videos, give credit on third party content and always follow the law. Respect everyone on Aworan, don’t spam people or post nudity.
                  </p>
                  <p className="my-2 text-gray-600 text-lg leading-relaxed justify-start content-start">
                  If you use any of our services, you are subject to our following rules. You agree that you will not do any of the following:
                  <br></br>
                    <br></br>
                  Engage in “framing,” “mirroring,” or otherwise simulating the appearance or function of the Services;
                  <br></br>
                    <br></br> Reverse engineer, decompile, disassemble, decipher or otherwise attempt to derive the source code for the Services or any related technology that we have not made open source;
                    <br></br>
                    <br></br>Override any security feature or bypass or circumvent any access controls or limits of the Services;
                    <br></br>
                    <br></br>Violate our intellectual property rights or the intellectual property rights of others;
                    <br></br>
                    <br></br>You would not post art by other artists without giving appropriate reference to the artists or the artists name.
                    <br></br>
                    <br></br>Create a false identity, misrepresent your identity, create a profile for anyone other than yourself, or use or attempt to use another’s account;
                    <br></br>
                    <br></br>Rent, lease, loan, trade, sell or otherwise monetize the Services or related data or access to the same, without our express written consent.

                  </p>

                  {/*  */}

                  <p className="my-4 text-black text-xl leading-relaxed font-semibold">
                  Regarding Nudity
                  </p>
                  <p className="my-4 text-gray-600 text-lg leading-relaxed justify-start content-start">
                  We are fully aware that nudity could sometimes be part of art and one's creativity but for a variety of reasons we do not allow nudity on this platform. If you have an art work of which its intention is to showcase the art more than the nudity, we do understand and allow such posts.
                    <br></br>
                  We do not accept art or pictures that show genitals, sexual intercourse and anything with the complete intent of showcasing nudity morethan the art.
                    <br></br>
                    <br></br>
                  Posts would be either flagged as nsfw or put in your archive as an admin sees fit. We would be in contact if such decisions are made.
                  </p>

                  {/*  */}

                  <p className="my-4 text-black text-xl leading-relaxed font-semibold">
                    Intellectual Property
                  </p>
                  <p className="my-4 text-gray-600 text-lg leading-relaxed justify-start content-start">
                    For the purposes of the Terms and the Privacy Policy, “personal information” is any information about an identifiable individual, as defined in our Privacy Policy.
                    <br></br>
                    <br></br>
                    Aworan retains the right to use or share any Aggregated Data generated by anyone using the Service, including our users, for the purpose of enhancing and providing the Service. “Aggregated Data” means data does not contain personal information and which has been manipulated or combined to provide generalized, anonymous information.
                  </p>

                  {/*  */}

                  <p className="my-4 text-black text-xl leading-relaxed font-semibold">
                    Google Play Services
                  </p>
                  <p className="my-4 text-gray-600 text-lg leading-relaxed justify-start content-start">
                    You should be aware that there are certain things that Aworan will not take responsibility for. Certain functions of the app will require the app to have an active internet connection. The connection can be Wi-Fi, or provided by your mobile network provider, but Aworan cannot take responsibility for the app not working at full functionality if you don’t have access to Wi-Fi, and you don’t have any of your data allowance left.
                    <br></br>
                    <br></br>
                    If you’re using the app outside of an area with Wi-Fi, you should remember that your terms of the agreement with your mobile network provider will still apply. As a result, you may be charged by your mobile provider for the cost of data for the duration of the connection while accessing the app, or other third party charges. In using the app, you’re accepting responsibility for any such charges, including roaming data charges if you use the app outside of your home territory (i.e. region or country) without turning off data roaming. If you are not the bill payer for the device on which you’re using the app, please be aware that we assume that you have received permission from the bill payer for using the app.
                    <br></br>
                    <br></br>
                    Along the same lines, Aworan cannot always take responsibility for the way you use the app i.e. You need to make sure that your device stays charged – if it runs out of battery and you can’t turn it on to avail the Service, Aworan cannot accept responsibility.
                    <br></br>
                    <br></br>
                    With respect to Aworan’s responsibility for your use of the app, when you’re using the app, it’s important to bear in mind that although we endeavour to ensure that it is updated and correct at all times, we do rely on third parties to provide information to us so that we can make it available to you. Aworan accepts no liability for any loss, direct or indirect, you experience as a result of relying wholly on this functionality of the app.
                    <br></br>
                    <br></br>
                      At some point, we may wish to update the app. The app is currently available on Android & iOS – the requirements for both systems(and for any additional systems we decide to extend the availability of the app to) may change, and you’ll need to download the updates if you want to keep using the app. Aworan does not promise that it will always update the app so that it is relevant to you and/or works with the Android & iOS version that you have installed on your device. However, you promise to always accept updates to the application when offered to you, We may also wish to stop providing the app, and may terminate use of it at any time without giving notice of termination to you. Unless we tell you otherwise, upon any termination, (a) the rights and licenses granted to you in these terms will end; (b) you must stop using the app, and (if needed) delete it from your device.

                  </p>

                  {/*  */}

                  <p className="my-4 text-black text-xl leading-relaxed font-semibold">
                    Changes to This Terms and Conditions
                  </p>
                  <p className="my-4 text-gray-600 text-lg leading-relaxed justify-start content-start">
                    We may update our Terms and Conditions from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Terms and Conditions on this page.
                    <br></br>
                    <br></br>
                    These terms and conditions are effective as of 2021-01-29
                  </p>

                  {/*  */}

                  <p className="my-4 text-black text-xl leading-relaxed font-semibold">
                    Contact Us
                  </p>
                  <p className="my-4 text-gray-600 text-lg leading-relaxed justify-start content-start">
                  If you have any questions or suggestions about our Terms and Conditions, do not hesitate to contact Aworan at [aworancontact@gmail.com].
                  </p>

                  {/*  */}
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-gray-300 rounded-b">
                  <button
                    className="bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                    type="button"
                    style={{ transition: "all .15s ease", background:"#CED213"}}
                    onClick={() => setShowModal(false)}
                  >
                    CLOSE
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}