import React, {useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom';
import {
    InfoSec,
    InfoRow,
    InfoColumn,
    TextWrapper,
    TopLine,
    NavBtnLink,
    Heading,
    Subtitle,
    ImgWrapper,
    Img
} from './NotFoundPageStyle.element.js';
import {Container, Button} from '../../globalStyles';
import first from '../../images/illustration/3.png';
import second from '../../images/illustration/7.png';
import third from '../../images/illustration/5.png';

const NotFoundPage = () => {
    let imgref = [first, second,  third];


    return (
        <>
            <InfoSec lightBg={true} id= {"404"}> 
            <Container>

                <InfoRow imgStart={true}>

                    <InfoColumn>
                        <TextWrapper>
                            <TopLine lightTopLine={false}>404 Error</TopLine>
                            <Heading lightText={false}>UH OH! You're lost.</Heading>
                            <Subtitle lightTextDesc={false}>The page you are looking for does not exist. How you got here is a mystery. But you can click the button below to go back to the homepage.</Subtitle>
                        
                            <a href="https://aworanapp.com/">
                                <Button big fontBig primary={true}>
                                    GO TO HOME
                                </Button>
                            </a>
                        </TextWrapper>
                    </InfoColumn>

                    <InfoColumn>
                        <ImgWrapper start={'true'}>
                            <Img src={imgref[0]} alt={"404"} />
                        </ImgWrapper>
                    </InfoColumn>
                    
                </InfoRow>
            </Container>
            </InfoSec>
        </>
    )
}

export default NotFoundPage
