import React, {useState, useEffect, useRef } from 'react'
import {
    InfoSec,
    InfoRow,
    InfoColumn,
    TextWrapper,
    TopLine,
    NavBtnLink,
    Heading,
    Subtitle,
    ImgWrapper,
    Img
} from './SuccessPageStyle.element.js';
import {Container, Button} from '../../globalStyles';
import third from '../../images/illustration/5.png';

const SuccessPage = () => {


    return (
        <>
            <InfoSec lightBg={true} id= {"404"}> 
            <Container>

                <InfoRow imgStart={true}>

                    <InfoColumn>
                        <TextWrapper>
                            <Heading lightText={false}>Success!!!</Heading>
                            <Subtitle lightTextDesc={false}>Thank you for joining the community.</Subtitle>
                        
                            <a href="https://aworanapp.com/">
                                <Button big fontBig primary={true}>
                                    GO TO HOME
                                </Button>
                            </a>
                        </TextWrapper>
                    </InfoColumn>

                    <InfoColumn>
                        <ImgWrapper start={'true'}>
                            <Img src={third} alt={"404"} />
                        </ImgWrapper>
                    </InfoColumn>
                    
                </InfoRow>
            </Container>
            </InfoSec>
        </>
    )
}

export default SuccessPage
