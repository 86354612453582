import styled from 'styled-components';
import { Link as LinkS} from 'react-scroll';
import googleplay from '../../images/stores/googleplay.png';
import appstore from '../../images/stores/appstore.png';

export const InfoSec = styled.div`
  color: #fff;
  padding: 160px 0;
  background: ${({ lightBg }) => (lightBg ? '#fff' : '#101522')};
`;

export const InfoRow = styled.div`
  display: flex;
  margin: 0 -15px -15px -15px;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: ${({ imgStart }) => (imgStart ? 'row-reverse' : 'row')};
`;

export const SpecialButton = styled.button`
border-radius: 4px;
background: ${({primary}) => (primary ? "#CED213" : '#0467FB')};
white-space: nowrap;
padding: ${({big}) => (big ? '12px 64px' : '15px 30px')};
font-weight: 600;
color: #fff;
font-size: ${({fontBig}) => (fontBig ? '20px' : '16px')};
margin-right: 10px;
margin-top: 10px;
outline: none;
border: none;
cursor: pointer;

&:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    background-color: ${({ primary }) => (primary ? '#0467FB' : '#4B59F7')};
  }
  @media screen and (max-width: 960px) {
    width: 100%;
  }
`

export const InfoColumn = styled.div`
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 50%;
  flex-basis: 50%;
  @media screen and (max-width: 768px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const StoreButtons = styled.div`
  border: 4px;
  background-image: url(${googleplay});
  width: 200px;
  height: 200px;
`;

export const StoreButtonsImg = styled.img`
  src: ${googleplay};
  width: 200px;
  height: 200px;
  position: relative;
`;

export const AppStore = styled.button`
  border-radius: 4px;
  background: url(${googleplay});
  white-space: nowrap;
  color: #fff;
  width: 200px;
  height: 200px;
  outline: none;
  border: none;
  cursor: pointer;
  &:hover {
      transition: all 0.3s ease-out;
      background: #fff;
    }
    @media screen and (max-width: 960px) {
      width: 100%;
    }
    
`;

export const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;
  @media screen and (max-width: 768px) {
    padding-bottom: 65px;
  }
`;

export const ImgWrapper = styled.div`
  max-width: 555px;
  display: flex;
  justify-content: ${({ start }) => (start ? 'flex-start' : 'flex-end')};
`;

export const TopLine = styled.div`
  color: ${({ lightTopLine }) => (lightTopLine ? '#a9b3c1' : '#4B59F7')};
  font-size: 18px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  margin-bottom: 16px;
`;

export const Img = styled.img`
  padding-right: 0;
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  max-height: 500px;
`;

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: ${({ lightText }) => (lightText ? '#f7f8fa' : '#1c2237')};
`;

export const Subtitle = styled.p`
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  color: ${({ lightTextDesc }) => (lightTextDesc ? '#a9b3c1' : '#1c2237')};
`;

export const NavBtnLink = styled(LinkS)`

  /* padding: 8px; */
`;