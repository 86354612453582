import React, {useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom';
import {
    InfoSec,
    InfoRow,
    InfoColumn,
    TextWrapper,
    TopLine,
    Heading,
    Subtitle,NavBtnLink,
    ImgWrapper,SpecialButton,
    AppStore,
    StoreButtonsImg,
    Img,
    StoreButtons
} from './InfoSection.element';
import {Container, Button} from '../../globalStyles';
import art_tat from '../../images/e8b923/art-tat.svg';
import art_museum from '../../images/e8b923/art-museum.svg';
import online_chat from '../../images/e8b923/online-chat.svg';
import undraw_sculpting from '../../images/e8b923/undraw-sculpting.svg';
import conceptual_idea from '../../images/e8b923/conceptual-idea.svg';
import appstore from '../../images/stores/appstore.png';
import googleplay from '../../images/stores/googleplay.png';

const InfoSection = ({
    primary,
    lightBg,
    topLine,
    lightTopLine,
    lightText,
    lightTextDesc,
    headline,
    description,
    buttonLabel,
    img,
    id,
    alt,
    imgStart,
    imgNum, 
    bShowTimer,
    start
  }) => {
      let imgref = [art_tat, art_museum,  online_chat, art_tat, undraw_sculpting, conceptual_idea];
      // let idRef = ["about", "one",  "three"];

      console.log(imgref[0]);

      const calculateTimeLeft = () => {
        let year = new Date().getFullYear();
        const difference = +new Date(`${year}-11-19`) - +new Date();
        let timeLeft = {};
    
        if (difference > 0) {
          timeLeft = {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60),
          };
        }
    
        return timeLeft;
      };
    
      const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
      const [year] = useState(new Date().getFullYear());
    
      useEffect(() => {
        setTimeout(() => {
          setTimeLeft(calculateTimeLeft());
        }, 1000);
      });
    
      const timerComponents = [];
    
      Object.keys(timeLeft).forEach((interval) => {
        if (!timeLeft[interval]) {
          return;
        }
    
        timerComponents.push(
          <span>
            {timeLeft[interval]} {interval}{"    "}
          </span>
        );
      });

      var timer;
      if(bShowTimer){
      timer= <p >{timerComponents.length ? timerComponents : <span>Time's up!</span> }</p> ;
      }

    const titleRef = useRef()

    function handleBackClick() {
        titleRef.current.scrollIntoView({ behavior: 'smooth' })
    }
    console.log(id + "console");

    return (
        <>
            <InfoSec lightBg={lightBg} ref={titleRef} id= {id}> 
            <Container>
                <InfoRow imgStart={imgStart}>
                    <InfoColumn>
                    <TextWrapper>
                        <TopLine lightTopLine={lightTopLine}>{topLine}</TopLine>
                        <Heading lightText={lightText}>{headline}</Heading>
                        <Subtitle lightTextDesc={lightTextDesc}>{description}</Subtitle>

                        <Subtitle lightTextDesc={lightTextDesc}>WE ARE FINALLY HERE !!!!</Subtitle>

                        {/* <>
                          <button >
                            <StoreButtonsImg>
                            </StoreButtonsImg>
                          </button>
                        </>

                        
                        <>
                          <button >
                            <img src={appstore} alt="my image" width="200" height="80"/>
                          </button>
                        </> */}

                      <a href="https://play.google.com/store/apps/details?id=com.kickbykick.art_network">
                        <SpecialButton fontBig primary={primary}>
                            GOOGLE PLAY
                        </SpecialButton>
                      </a>

                      <a href="https://apps.apple.com/app/id1551892149">
                        <SpecialButton  fontBig primary={primary}>
                            APPLE STORE
                        </SpecialButton>
                      </a>
                        
                    </TextWrapper>
                    </InfoColumn>

                    <InfoColumn>
                      <ImgWrapper start={start}>
                          <Img src={imgref[imgNum]} alt={alt} />
                      </ImgWrapper>
                    </InfoColumn>
                </InfoRow>
            </Container>
            </InfoSec>
        </>
    )
}

export default InfoSection
