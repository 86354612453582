import React from 'react';
import {homeObjOne, homeObjTwo, homeObjThree} from './Data';
import { InfoSection, NoButtonInfoSection, NotFoundPage } from '../../components';

const Home = () => {
    return (
        <>
            <InfoSection {...homeObjOne} />
            <NoButtonInfoSection {...homeObjTwo} />
            <NoButtonInfoSection {...homeObjThree} />
        </>
    );
}

export default Home;