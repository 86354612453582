import React, { useState } from 'react'
import { Button } from '../../globalStyles';
import { Element, animateScroll as scroll} from 'react-scroll'
import emailjs from "emailjs-com";
import Modal from 'react-modal';
// import '../../style/tailwind.css';

import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaLinkedin,
  FaDiscord,
} from 'react-icons/fa';
import PrivacyPolicyModal from '../modalPrivacyPolicy.js'
import TermsAndConditionModal from '../modalTerms.js'
import {
  FooterContainer,
  FooterSubscription,
  FooterSubText,
  FooterSubHeading,
  Form,
  FormInput,PolicyFooters,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  SocialIcon,
  WebsiteRights,
  SocialIcons,
  SocialIconLink,
  HeaderImg
} from './Footer.element';

const Footer = () => {
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }
  
  const [showPrivacy, setShowPrivacy] = React.useState(false);
  const [showTerms, setShowTerms] = React.useState(false);
  const [title, setTitle] = useState("Click here");

  function clickPrivacy(){ 
    setShowPrivacy(!showPrivacy);
    console.log(`In --------------------   ${showPrivacy}`);
  }

  function clickTerms(){ 
    setShowTerms(!showTerms);
    console.log(`In --------------------   ${showTerms}`);
  }
  // const [email, setEmail] = useState('');

  // const submitRequest = async (e) => {
  //   e.preventDefault();
  //   console.log({ email });
  //   const response = await fetch("/access", { 
  //     method: 'POST', 
  //     headers: { 
  //         'Content-type': 'application/json'
  //     }, 
  //     body: JSON.stringify({email}) 
  // }); 
  //   const resData = await response.json(); 
  //   if (resData.status === 'success'){
  //     alert("Message Sent."); 
  //     this.resetForm()
  // }else if(resData.status === 'fail'){
  //     alert("Message failed to send.")
  // }
  // };

  function sendEmail(e){  
    e.preventDefault();
    emailjs.sendForm('gmail', 'template_ld4e5bb', e.target, 'user_jAtkLfGNA80Q0FyVQ9haA')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      alert("Thank you for signing up. Email sent.");
      e.target.reset()
  }

    return (
      <Element  name="footer" className="element">
        <FooterContainer>
      <FooterSubscription>
        <FooterSubHeading>
          Join the beta tests on release and help us build this ecosystem
        </FooterSubHeading>
        {/* <FooterSubText>You can unsubscribe at any time.</FooterSubText> */}
        <Form onSubmit={sendEmail}>
          <FormInput name='email' type='email'  placeholder='Your Email' />
          <FormInput name='os' type='text' type="text" placeholder='IOS or Android'/>
          <Button primary fontBig type="submit">Subscribe</Button>
        </Form>
      </FooterSubscription>
      
      
      <SocialMedia>
      <SocialMediaWrap>
      <FooterLinkItems>
        
      </FooterLinkItems>
      <FooterLinkItems>
      {/* <div>
      <button onClick={openModal}>Open Modal</button>
       <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        // style={customStyles}
        contentLabel="Example Modal"
      >
        <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2>
        <button onClick={closeModal}>close</button>
        <div>I am a modal</div>
        <form>
          <input />
          <button>tab navigation</button>
          <button>stays</button>
          <button>inside</button>
          <button>the modal</button>
        </form>
      </Modal> 
    </div> */}
        <PrivacyPolicyModal></PrivacyPolicyModal>
        <TermsAndConditionModal></TermsAndConditionModal >
      </FooterLinkItems>
        
      <FooterLinkItems>
      </FooterLinkItems>

      </SocialMediaWrap>
        <SocialMediaWrap>
          <SocialLogo to='/'>
            <HeaderImg />
            AWORAN
          </SocialLogo>
          <WebsiteRights>AWORAN © 2020</WebsiteRights>
          <SocialIcons>

          <SocialIconLink href={
              'https://discord.gg/aFvaaaNS9E'
            } target='_blank' aria-label='Discord'>
            <FaDiscord/>
          </SocialIconLink>

          <SocialIconLink href={
              'https://www.youtube.com/channel/UCDWgtvCK2LX5y6yxLSt_J0A'
            } target='_blank' aria-label='Youtube'>
            <FaYoutube/>
          </SocialIconLink>

          <SocialIconLink href={"https://www.facebook.com/aworan.app.31"} target='_blank' aria-label='Facebook'>
            <FaFacebook />
          </SocialIconLink>
          
            {/* <SocialIconLink
              href={
                '//www.youtube.com/channel/UCsKsymTY_4BYR-wytLjex7A?view_as=subscriber'
              }
              rel='noopener noreferrer'
              target='_blank'
              aria-label='Youtube'
            >
              <FaYoutube />
            </SocialIconLink> */}
            

            
            <SocialIconLink href={
                'https://www.instagram.com/aworanapp/'
              } target='_blank' aria-label='Instagram'>
              <FaInstagram />
            </SocialIconLink>
            <SocialIconLink href={
                'https://twitter.com/aworanapp'
              } target='_blank' aria-label='Twitter'>
              <FaTwitter />
            </SocialIconLink>
            <SocialIconLink href='https://www.linkedin.com/in/aworan-app-568940205/' target='_blank' aria-label='LinkedIn'>
              <FaLinkedin />
            </SocialIconLink>
          </SocialIcons>
        </SocialMediaWrap>
      </SocialMedia>
    </FooterContainer>
    </Element>
    );
}

export default Footer
