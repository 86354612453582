import React from 'react';
import ReactDOM from 'react-dom';
// import { Router, Route,} from 'react-router';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import App from './App';
// import HomeNew from './Home';
// import UpdateEmail from './UpdateEmail';
// import UpdatePassword from './UpdatePassword';
import Action from './Action';
import GlobalStyle from './globalStyles'
// import NotFound from './components/404/NotFoundPage';
import { NotFoundPage, SuccessPage } from './components';


export const NotFoundHere = () => {
  return (
      <>
        <GlobalStyle />
        <NotFoundPage/>
      </>
  );
}

export const SuccessPageHere = () => {
  return (
      <>
        <GlobalStyle />
        <SuccessPage/>
      </>
  );
}

ReactDOM.render(
  <Router >
    <Switch>
      <Route exact path="/" component={App} />
      <Route path="/action" component={Action} />
      <Route path="/success" component={SuccessPageHere} />
      <Route path='*' exact={true} component={NotFoundHere} />
    </Switch>

    {/* <Route path="/" component={Test}>
      <IndexRoute component={App} />
      <Route path="/home">
        <IndexRoute component={HomeNew} />
        <Route path="/home/update-email" component={UpdateEmail} />
        <Route path="/home/update-password" component={UpdatePassword} />
      </Route>
      <Route path="/action" component={Action} />
      <Route component={NotFound} />
    </Route> */}
  </Router>,
  document.getElementById('root')
);


