import React from "react";
import '../style/modal.css';

export default function Modal() {
  const [showModal, setShowModal] = React.useState(false);
  return (
    <>
      <p
        className="text-white"
        // type="button"
        style={{ transition: "all .15s ease" }}
        onClick={() => setShowModal(true)}
      >
        Privacy Policy
      </p>
      {showModal ? (
        <>
          <div
            className="modal h-2/3 overflow-y-auto justify-center items-center overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none"
            onClick={() => setShowModal(false)}
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                  <h3 className="text-3xl text-black font-semibold">
                    Privacy Policy
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto text-left">
                  
                  <p className="my-4 text-gray-600 text-lg leading-relaxed justify-start content-start">
                    Aworan built the [Aworan] app as a Free app. This SERVICE is provided by Aworan at no cost and is intended for use as is.
                    <br></br>
                    <br></br>
                    This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service.
                    <br></br>
                    <br></br>
                    If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.
                    <br></br>
                    <br></br>
                    The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at [www.aworanapp.com or on the app] unless otherwise defined in this Privacy Policy.
                  </p>

                  <p className="my-4 text-black text-xl leading-relaxed font-semibold">
                    Information Collection and Use
                  </p>

                  <p className="my-4 text-gray-600 text-lg leading-relaxed justify-start content-start">
                    For a better experience, while using our Service, we may require you to provide us with certain personally identifiable information. The information that we request will be retained on your device and is not collected by us in any way.
                    <br></br>
                    <br></br>
                    The app does use third party services that may collect information used to identify you except for the payment service.
                    Stripe is our main payment service and to verify your bank and payment information, Stripe collects some information for their verification process.
                    <br></br>
                    <br></br>
                    This is a link to stripe’s payment service [https://stripe.com/en-ca/privacy].
                  </p>

                  <p className="text-black text-xl leading-relaxed font-semibold">
                    Google Play Services
                  </p>
                  <p className="text-black text-xl leading-relaxed font-medium">
                    Log Data
                  </p>

                  <p className="my-4 text-gray-600 text-lg leading-relaxed justify-start content-start">
                    We want to inform you that whenever you use our Service, in a case of an error in the app we collect data and information (through third party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilizing our Service, the time and date of your use of the Service, and other statistics.
                  </p>

                  <p className="my-4 text-black text-xl leading-relaxed font-semibold">
                    Cookies
                  </p>

                  <p className="my-4 text-gray-600 text-lg leading-relaxed justify-start content-start">
                    Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device's internal memory.
                    <br></br>
                    This Service does not use these “cookies” explicitly. However, the app may use third party code and libraries that use “cookies” to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.
                  </p>

                  {/*  */}

                  <p className="my-4 text-black text-xl leading-relaxed font-semibold">
                    Service Providers
                  </p>
                  <p className="my-4 text-gray-600 text-lg leading-relaxed justify-start content-start">
                    We may employ third-party companies and individuals due to the following reasons:
                    <br></br>
                    To facilitate our Service;<br></br>
                    To provide the Service on our behalf;<br></br>
                    To perform Service-related services; or<br></br>
                    To assist us in analyzing how our Service is used.<br></br>
                    We want to inform users of this Service that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.
                  </p>

                  {/*  */}

                  <p className="my-4 text-black text-xl leading-relaxed font-semibold">
                    Security
                  </p>
                  <p className="my-4 text-gray-600 text-lg leading-relaxed justify-start content-start">
                    We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and We cannot guarantee its absolute security.
                    <br></br><br></br>
                    While we collect some of your personal information, we would not sell your data and none of our employees are allowed to use your information for malicious purposes.
                  </p>

                  {/*  */}

                  <p className="my-4 text-black text-xl leading-relaxed font-semibold">
                    Links to Other Sites
                  </p>

                  <p className="my-4 text-gray-600 text-lg leading-relaxed justify-start content-start">
                  This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.
                  </p>

                  {/*  */}

                  <p className="my-4 text-black text-xl leading-relaxed font-semibold">
                    Age of Consent
                  </p>

                  <p className="my-4 text-gray-600 text-lg leading-relaxed justify-start content-start">
                  These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions.
                  </p>

                  {/*  */}

                  <p className="my-4 text-black text-xl leading-relaxed font-semibold">
                    Changes to This Privacy Policy
                  </p>
                  <p className="my-4 text-gray-600 text-lg leading-relaxed justify-start content-start">
                  We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. If the change materially affects the treatment of your personal data, and we have your email but you are not subscribed to the Company News list, we will send you an email.
                  <br></br><br></br>
                  This policy is effective as of 2021-01-27
                  </p>

                  {/*  */}
                  
                  <p className="my-4 text-black text-xl leading-relaxed font-semibold">
                    Contact Us
                  </p>
                  <p className="my-4 text-gray-600 text-lg leading-relaxed justify-start content-start">
                  If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at [aworancontact@gmail.com].                  </p>

                  {/*  */}
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-gray-300 rounded-b">
                  <button
                    className=" text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                    type="button"
                    style={{ transition: "all .15s ease", background:"#CED213"}}
                    onClick={() => setShowModal(false)}
                  >
                    CLOSE
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}