import React, {useState, useEffect} from 'react';
import {FaBars, FaTimes} from 'react-icons/fa';
import {Button} from '../../globalStyles'
import {IconContext} from 'react-icons/lib'
import {animateScroll as scroll, Link, scroller} from 'react-scroll';
import Scrollspy from 'react-scrollspy'

import {
    Nav, 
    NavbarContainer, 
    NavIcon, 
    NavLogo, 
    HamburgerIcon,
    NavMenu, 
    NavLinks, 
    NavItemBtn,
    NavItem,
    NavBtnLink,
    NavScrollLinks,
    HeaderImg
} from './Navbar.element';
// import { Link, animateScroll as scroll } from "react-scroll";

const Navbar = () => {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const showButton = () => {
        if (window.innerWidth <= 960) {
          setButton(false);
        } else {
          setButton(true);
        }
    };

    useEffect(() => {
        showButton();
    }, []);

    const toggleHome = () => {
        scroll.scrollToTop()
    }

   
      
    return (
        <>
        <IconContext.Provider value = {{ color: '#fff'}}>

            <Nav>
                <NavbarContainer>
                    <NavLogo to = "/" onClick={toggleHome}>
                    <HeaderImg />
                        {/* <NavIcon /> */}
                        Aworan
                    </NavLogo>
                    <HamburgerIcon onClick={handleClick}>
                        {click ? <FaTimes /> : <FaBars />}
                    </HamburgerIcon>

                    <NavMenu onClick={handleClick} click={click} >
                        <NavItem>
                            <NavScrollLinks to='one' activeClass="active" onClick={closeMobileMenu} exact="true" spy={true} smooth={true} offset={700} duration={500} delay={500}>
                            
                                About
                            </NavScrollLinks>
                        </NavItem>
                   
                        <NavItemBtn>
                            {button ? (
                            <NavBtnLink  to='footer' activeClass="active" onClick={closeMobileMenu} exact="true" spy={true} smooth={true} offset={500} duration={500} delay={500}>
                                <Button primary>SUBSCRIBE</Button>
                            </NavBtnLink>
                            ) : (
                            <NavBtnLink to='footer' activeClass="active" onClick={closeMobileMenu} exact="true" spy={true} smooth={true} offset={500} duration={500} delay={500}>
                                <Button onClick={closeMobileMenu} fontBig primary>
                                SUBSCRIBE
                                </Button>
                            </NavBtnLink>
                            )}
                        </NavItemBtn>
                    </NavMenu>
                </NavbarContainer>
            </Nav>

        </IconContext.Provider>
        </>
    )
}

export default Navbar