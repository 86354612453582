import React from 'react';
import GlobalStyle from './globalStyles'
import { BrowserRouter as Router} from 'react-router-dom';
import { createHashHistory } from 'history'

import Home  from './pages/HomePage/Home';
import { Navbar, Footer } from './components';
// import HomeNew from './Home';
// import UpdateEmail from './UpdateEmail';
// import Action from './Action';

const history = createHashHistory()
class App extends React.Component{
 
  render() {
    
    return (
      // <Router >

       <Router history={history}>
        {/* <Route path="/" component={Home}> */}
        <GlobalStyle />
        <Navbar/>
          <Home />
          {/* <Switch> */}
            {/* <Route path="/home">
              <IndexRoute component={HomeNew} />
              <Route path="/home/update-email" component={UpdateEmail} />
              <Route path="/home/update-password" component={UpdatePassword} />
            </Route>
            <Route path="/action" component={Action} /> */}
          {/* </Switch> */}
          <Footer  id="footer"/>
        {/* </Route> */}
      </Router>
    );
  }
}



export default App;