import React, {useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom';
import {
    InfoSec,
    InfoRow,
    InfoColumn,
    TextWrapper,
    TopLine,
    Heading,
    Subtitle,NavBtnLink,
    ImgWrapper,
    Img
} from './NoButtonInfoSection.element';
import {Container, Button} from '../../globalStyles';
import art_tat from '../../images/e8b923/art-tat.svg';
import art_museum from '../../images/e8b923/art-museum.svg';
import making_art from '../../images/e8b923/making-art.svg';
import online_chat from '../../images/e8b923/online-chat.svg';
import undraw_sculpting from '../../images/e8b923/undraw-sculpting.svg';
import conceptual_idea from '../../images/e8b923/conceptual-idea.svg';

const NoButtonInfoSection = ({
    primary,
    lightBg,
    topLine,
    lightTopLine,
    lightText,
    lightTextDesc,
    headline,
    description,
    buttonLabel,
    img,
    id,
    alt,
    imgStart,
    imgNum, 
    bShowTimer,
    start
  }) => {
      let imgref = [art_museum, art_museum,  online_chat , art_tat, undraw_sculpting, conceptual_idea];
      let idRef = ["about", "one",  "three"];

      console.log(imgref[0]);

      const calculateTimeLeft = () => {
        let year = new Date().getFullYear();
        const difference = +new Date(`${year}-11-19`) - +new Date();
        let timeLeft = {};
    
        if (difference > 0) {
          timeLeft = {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60),
          };
        }
    
        return timeLeft;
      };
    
      const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
      const [year] = useState(new Date().getFullYear());
    
      useEffect(() => {
        setTimeout(() => {
          setTimeLeft(calculateTimeLeft());
        }, 1000);
      });
    
      const timerComponents = [];
    
      Object.keys(timeLeft).forEach((interval) => {
        if (!timeLeft[interval]) {
          return;
        }
    
        timerComponents.push(
          <span>
            {timeLeft[interval]} {interval}{"    "}
          </span>
        );
      });

      var timer;
      if(bShowTimer){
      timer= <p >{timerComponents.length ? timerComponents : <span>Time's up!</span> }</p> ;
      }

    const titleRef = useRef()

    function handleBackClick() {
        titleRef.current.scrollIntoView({ behavior: 'smooth' })
    }
    console.log(id + "console");

    return (
        <>
            <InfoSec lightBg={lightBg} ref={titleRef} id= {id}> 
            <Container>
                <InfoRow imgStart={imgStart}>
                    <InfoColumn>
                    <TextWrapper>
                        <TopLine lightTopLine={lightTopLine}>{topLine}</TopLine>
                        <Heading lightText={lightText}>{headline}</Heading>
                        <Subtitle lightTextDesc={lightTextDesc}>{description}</Subtitle>

                        <Subtitle lightTextDesc={lightTextDesc}>{timer}</Subtitle>
{/* 
                      <NavBtnLink to='footer' activeClass="active" exact="true" spy={true} smooth={true} offset={500} duration={500} delay={500}>
                        <Button big fontBig primary={primary}>
                            {buttonLabel}
                        </Button>
                      </NavBtnLink> */}
                    </TextWrapper>
                    </InfoColumn>
                    <InfoColumn>

                    <ImgWrapper start={start}>
                        <Img src={imgref[imgNum]} alt={alt} />
                    </ImgWrapper>
                    </InfoColumn>
                </InfoRow>
            </Container>
            </InfoSec>
        </>
    )
}

export default NoButtonInfoSection
