export const homeObjOne = {
    id: "one",
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Aworan',
    headline: 'A Global community To Foster Art',
    description:
      'Connect with artists from across the world.',
    buttonLabel: 'GET NOTIFIED',
    imgStart: '',
    img: '../../assets/public/aworan_logo.png',
    imgNum: 0,
    bShowTimer: true,
    alt: 'Aworan',
    start: ''
  };

  export const homeObjTwo = {
    id: "about",
    primary: false,
    lightBg: true,
    lightTopLine: false,
    lightText: false,
    lightTextDesc: false,
    topLine: '',
    headline: 'We Believe in Concentration of Artistic Talent',
    description:
      "To be or not to be is a statement that encapsulates our oasis on this earth. Do you want to be an artist? The choice is yours. Connect with like minded people on Aworan, for more experience, outreach and information on the artworld.",
    buttonLabel: 'Learn More',
    imgStart: 'start',
    imgNum: 1,
    img: require('../../images/svg-2.svg'),
    alt: 'Vault',
    start: 'true',
    bShowTimer: false,
  };
  
  export const homeObjThree = {
    id: "three",
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Instant Messaging',
    headline:
      'Collaborate with any artists around the world.',
    description:
      "Are you attracted to an Artwork or Art Show? Follow and collaborate with any artist.",
    buttonLabel: 'View Case Study',
    imgStart: '',
    img: require('../../images/svg-2.svg'),
    alt: 'Vault',
    start: 'true',
    imgNum: 2,
    bShowTimer: false,
  };

  export const homeObjFour = {
    id: "four",
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Secure Database',
    headline: 'All your data is stored on our secure server',
    description:
      'You will never have to worry about your information getting leaked. Our team of security experts will ensure your records are kept safe.',
    buttonLabel: 'Sign Up Now',
    imgStart: 'start',
    img: require('../../images/svg-3.svg'),
    alt: 'Vault',
    bShowTimer: false,
    start: 'true',
    imgNum: 3,
  };

  export const homeObjFive = {
    id: "five",
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Secure Database',
    headline: 'All your data is stored on our secure server',
    description:
      'You will never have to worry about your information getting leaked. Our team of security experts will ensure your records are kept safe.',
    buttonLabel: 'Sign Up Now',
    imgStart: 'start',
    img: require('../../images/svg-3.svg'),
    alt: 'Vault',
    bShowTimer: false,
    start: 'true',
    imgNum: 4,
  };